import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <h2>{`Description`}</h2>
        <p>{`One of the best supported and most widely used types of embedded CPUs is Intel‘s
MCS 51 series of devices. This is enough reason to create an 8051-compatible CPU
core for FPGA usage. Use of this core makes very much sense when porting existing
designs to FPGA technology, or as an easy-to-adapt reference application.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "518px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/25dce987f576ecccecdd778e72605c6c/6b9fd/8051_med_hr.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "99.33333333333331%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjUlEQVQ4y3WUW4/aQAyF8///Em+8dLUV0nahoADLkhuEXEi4QwK4+kwcUdSONEoyto+Pjz1xbrebXC4Xqaqq3Xxz7vu+dLtdORwOMuj35f39p9R1rXbbz3Hn81mc6/Wqhvv9riA8OWOFYSA/3t5ku93KdDqVj49frQ2/19UCkvVfa7/fS5ZlUpalxHEsy+VSwWHMxr7b7fTJgqUC2iEZjHpdX6UoClmv13oGGMC844/teDxqdfiAo4Cww9l1XUnTTJIkkTAMpShKdZxMJnKpKmXoB4EyWa1W8vU1k3y9VsZUYZU6aEHgYrFQI0wBtFKDINDyAGTzDiD+VMIirgWkEZSQ57kaCII6gJRzOp3asgnAB0AAiIEQZ9i0ZADJZBtGJIAJC7uJznrIMNVkAAGIP35tUzabjSRJqk6UGUWRaokzwsOIAFZdVXrGN3GQwA4BBaxMcN+XPF8rKIDLZawAdNPzPA22AaY8NmdlUYjrjiWKFspeS7ZhtbXd7pQF5zAAdDwe62APBr9lOBpp92kelUAoy3KdFsfm53n6oR9FoSRpqkGI783nMhyO5Pt7LvP5XDzPV5t1nHf0d6w7r4tyrPN2b2Hre4GUTfmwoyG+H8gyjhXYUaHrWu6U3lxBRiUIQ4kbsbHzBJDySMI7gCSm/IfuC3FWSaIlpFnW6LFSzXhP01TnkWAbeLNxho1vu6KqITQRnEY8DIU6kplEPAGzjQ9SEEfCsvkmRgFpxKUZBR2L+qEn2sxmMzkcjn9pix/ANADQ52ai839/X2jG/Nl9tUVSWIbN8L/aFJAmPP+JyQiDTqejZdhf3QaaEvmT93q99spxzuw69oe2rR0XUW0+P/vtfX32YY3dsUryavsDfAD89fZhix0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "8051 Core for FPGA Implementation",
                "title": "8051 Core for FPGA Implementation",
                "src": "/static/25dce987f576ecccecdd778e72605c6c/6b9fd/8051_med_hr.png",
                "srcSet": ["/static/25dce987f576ecccecdd778e72605c6c/5a46d/8051_med_hr.png 300w", "/static/25dce987f576ecccecdd778e72605c6c/6b9fd/8051_med_hr.png 518w"],
                "sizes": "(max-width: 518px) 100vw, 518px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Features`}</p>
        <ul>
          <li parentName="ul">{`cycle compatible to MCS 51`}
            <ul parentName="li">
              <li parentName="ul">{`supported by a wide range of development tools`}</li>
            </ul>
          </li>
          <li parentName="ul">{`very compact design`}
            <ul parentName="li">
              <li parentName="ul">{`on-chip 128 byte data RAM`}</li>
              <li parentName="ul">{`minimum gate count`}</li>
              <li parentName="ul">{`optimized for FPGA implementation`}</li>
            </ul>
          </li>
          <li parentName="ul">{`lowest possible design risk`}
            <ul parentName="li">
              <li parentName="ul">{`free behavioral model`}</li>
              <li parentName="ul">{`comprehensive reference application`}</li>
              <li parentName="ul">{`synthesizable VHDL model`}</li>
            </ul>
          </li>
          <li parentName="ul">{`very low cost`}</li>
        </ul>
        <h2>{`Synthesis`}</h2>
        <p>{`The core was successfully synthesized with Synopsys FPGA Express and Xilinx XST.
This is a summary of the required resources:`}</p>
        <ul>
          <li parentName="ul">{`total number of 4 input LUTs: 1,934`}
            <ul parentName="li">
              <li parentName="ul">{`number used as LUTs: 1,846`}</li>
              <li parentName="ul">{`number used as route-thru: 24`}</li>
              <li parentName="ul">{`number used as 32×1 RAMs: 64`}</li>
            </ul>
          </li>
          <li parentName="ul">{`number of slice flip flops: 353`}</li>
          <li parentName="ul">{`total equivalent gate count: 23,299`}</li>
        </ul>
        <h2>{`Verification`}</h2>
        <p>{`The CPU core was verified on several levels:`}</p>
        <ul>
          <li parentName="ul">{`simulation of every single instruction`}</li>
          <li parentName="ul">{`simulation of test programs`}</li>
          <li parentName="ul">{`real-life test with reference applications`}</li>
        </ul>
      </Main>
      <Aside mdxType="Aside">
        <p>{`Resources:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "ps-te51.pdf"
            }}>{`product specification`}</a></li>
        </ul>
      </Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      